var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

jQuery(document).ready(function () {

  jQuery('.toggle-navigation').on('click', function (event) {
    jQuery('body').toggleClass('navigation-open');
    event.preventDefault();
  });

  jQuery('.expand a').on('click', function (event) {
    jQuery(this).closest('.info-wrapper').find('.extra-info').slideDown();
    jQuery(this).hide();
    event.preventDefault();
  });

  jQuery('header a.local').on('click', function (event) {
    var section = jQuery(this).attr('href');
    jQuery('html, body').animate({
      scrollTop: jQuery(section).offset().top - 101
    }, 1500);
    event.preventDefault();
  });

  if (jQuery('.gallery-slider').length) {
    var $gallerySlider = jQuery('.gallery-slider').slick({
      arrows: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    jQuery('.slider-wrapper .control-prev').on('click', function (event) {
      $gallerySlider.slick('slickPrev');
      event.preventDefault();
    });

    jQuery('.slider-wrapper .control-next').on('click', function (event) {
      $gallerySlider.slick('slickNext');
      event.preventDefault();
    });

    jQuery('.slider-wrapper').magnificPopup({
      delegate: '.gallery-item:not(.slick-cloned) a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery: {
        enabled: true
      }
    });
  }

  if (jQuery('#counter').length) {
    jQuery('#counter').countdown('2018/05/01 10:00:00', function (event) {
      var $this = jQuery(this).html(event.strftime(''
          + '<div class="partial"><div class="unit"><span class="number">%D</span> <span class="label">days</span></div></div>'
          + '<div class="partial"><div class="unit"><span class="number">%H</span> <span class="label">hours</span></div></div>'
          + '<div class="partial"><div class="unit"><span class="number">%M</span> <span class="label">minutes</span></div></div>'
          + '<div class="partial"><div class="unit"><span class="number">%S</span> <span class="label">seconds</span></div></div>'));
    });
  }

  jQuery(window).on('load', function () {

  });

  jQuery(window).on('scroll', function () {
    var topPosition = jQuery(window).scrollTop();

    if (topPosition > 700 && windowWidth >= 1025) {
      if (!jQuery('header').hasClass('distinct')) {
        jQuery('header').addClass('distinct');
      }
    } else if (topPosition < 700 && windowWidth >= 1025) {
      if (jQuery('header').hasClass('distinct')) {
        jQuery('header').removeClass('distinct');
      }
    }
  });

  jQuery(window).on('resize', function () {
    windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  });
});